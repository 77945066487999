<template>
  <div class="assumption">
    <div
      @click="isPremiseModalOpen = true"
      class="assumption__select__option"
      v-chakra="{
        color: selectedAssumption.color,
        backgroundColor: selectedAssumption.colorRgba,
      }"
    >
      <svg v-chakra="{ fill: selectedAssumption.color }">
        <use href="@/assets/icons/shield-star-fill.svg#shield-star"></use>
      </svg>
      <span>
        {{ selectedAssumption.name }}
      </span>
    </div>

    <c-modal
      :is-open="isPremiseModalOpen"
      :on-close="closePremiseModal"
      :closeOnOverlayClick="false"
      isCentered
    >
      <c-modal-content
        ref="content"
        w="25em"
        px="8"
        py="5"
        maxWidth="100%"
        borderRadius="8px"
        overflow="hidden"
      >
        <c-modal-close-button />
        <div v-if="form.step === 0">
          <c-text align="center" mb="5" pt="5" class="font-bold"
            >Is this
            <span
              class="font-bold"
              v-chakra="{
                color: '#ef7923',
              }"
              >{{ title }}</span
            >
            assumption verified?</c-text
          >
          <c-flex justify-content="center">
            <c-radio-group variant-color="green" v-model="form.selectedStatus">
              <c-radio
                :value="status"
                v-for="(status, ix) in statusOptions"
                :key="ix"
                >{{ status }}</c-radio
              >
            </c-radio-group>
          </c-flex>
        </div>
        <div v-if="form.step === 1">
          <c-text align="center" mb="5" pt="5" class="font-bold"
            >The premise of this assumption is verified through</c-text
          >
          <c-flex justify-content="center">
            <c-checkbox-group
              variant-color="green"
              v-model="form.selectedPremise"
            >
              <c-checkbox
                mx="2"
                :value="premise"
                v-for="(premise, ix) in premiseOptions"
                :key="ix"
                >{{ premise }}</c-checkbox
              >
            </c-checkbox-group>
          </c-flex>
        </div>
        <div v-if="form.step === 2">
          <c-text align="center" mb="5" pt="5" class="font-bold"
            >Congratulation! This is a very important milestone!</c-text
          >
          <c-text align="center">🎉🎉🎈😄</c-text>
        </div>
        <c-box v-if="form.step === 3">
          <c-text align="center" mb="5" pt="5" class="font-bold"
            >Maximize your time efficiently by trying to verify your assumption!
            Maybe you should consider Pivoting your business?</c-text
          >
          <c-text align="center" color="#ef7923"
            >Did you know that research shows that you increase your success
            rate the more you actively pivot your business!</c-text
          >
          <c-flex direction="column" justify-content="center">
            <c-button
              variant-color="vc-orange"
              my="4"
              @click="() => onValidateAssumption('in-progress')"
            >
              Verify and validate your assumption
            </c-button>
            <!-- @click="() => onFinish('in-progress')" -->
            <c-button variant-color="blue" @click="() => onFinish('failed')">
              I need time to pivot my business
            </c-button>
          </c-flex>
        </c-box>
        <c-flex
          justify-content="center"
          pt="5"
          v-if="form.step !== 3 && form.step !== 2"
        >
          <c-button
            variant-color="vc-orange"
            mr="4"
            @click="onBack"
            v-if="form.step > 0"
          >
            Back
          </c-button>
          <!-- <c-button variant-color="vc-orange" mr="4">
            View validated comments
          </c-button> -->
          <c-button
            variant-color="blue"
            @click="() => onDone(true)"
            :disabled="isDisabled"
          >
            Done
          </c-button>
        </c-flex>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>

    <c-modal
      :is-open="isValidateModalOpen"
      :on-close="closeValidateModal"
      :closeOnOverlayClick="false"
      isCentered
    >
      <c-modal-content
        ref="content"
        w="25em"
        px="5"
        py="5"
        maxWidth="100%"
        borderRadius="8px"
        overflow="hidden"
      >
        <c-modal-close-button />
        <c-text mb="5" pt="5" class="font-bold"
          >Validate your
          <span
            class="font-bold"
            v-chakra="{
              color: '#ef7923',
            }"
            >{{ title }}</span
          >
          with the Vibrant Community and receive feedback from others.</c-text
        >
        <EditableText
          v-model="result"
          @updateDescription="updateDescription"
          :title="title"
          :type="title"
          :disabled="true"
        />

        <c-button variant-color="blue" @click="postValidate">
          Post to community for validation
        </c-button>
      </c-modal-content>
    </c-modal>
  </div>
</template>

<script>
// import { getAI } from '../../../services/openAI';
import EditableText from './EditableText';

export default {
  props: ['disabled', 'value', 'title', 'descriptionData'],
  components: {
    EditableText,
  },
  data() {
    return {
      isValidateModalOpen: false,
      assumption: 'unverified',
      isPremiseModalOpen: false,
      form: {
        // stepValue: 'No',
        step: 0,
        selectedPremise: [],
        selectedStatus: '',
      },
      assumptionLevels: [
        {
          name: 'Unverified assumption',
          value: 'unverified',
          color: '#ef7923',
          colorRgba: 'rgba(239, 121, 35, 0.1)',
        },
        {
          name: 'Assumption Verified',
          value: 'verified',
          color: '#1a86d0',
          colorRgba: 'rgba(26, 134, 208, 0.1)',
        },
        {
          name: 'Assumption Failed',
          value: 'failed',
          color: '#ee2f1f',
          colorRgba: 'rgba(238, 48, 31, 0.1)',
        },
      ],
      assumptionSteps: [
        {
          name: 'Verify Assumption',
          value: 'unverified',
          color: '#ef7923',
          colorRgba: 'rgba(239, 121, 35, 0.1)',
        },
        {
          name: 'Verified Assumption',
          value: 'verified',
          color: '#1a86d0',
          colorRgba: 'rgba(26, 134, 208, 0.1)',
        },
        {
          name: 'Assumption in progress! \nPivot?',
          value: 'in-progress',
          color: '#27B18F',
          colorRgba: 'rgba(39, 177, 143, 0.1)',
        },
        {
          name: 'I need to pivot?',
          value: 'failed',
          color: '#EF3E2F',
          colorRgba: 'rgba(239, 62, 47, 0.1)',
        },
      ],
      premiseOptions: [
        'Academic literature',
        'Q&A with customer base',
        'Market observations',
        'Conversation with customers',
        'Survey from customers',
        'Research reports',
        'Testing',
        'Feedback from customers',
        'Other',
      ],
      statusOptions: ['No', 'Not yet! I need time', 'Yes'],
      description: this.descriptionData,
      result: '',
    };
  },
  methods: {
    updateDescription(newAIresults) {
      this.description = newAIresults;
    },
    postValidate() {
      const payload = {
        description: this.descriptionData,
        field: this.title,
      };

      console.log(payload);
    },
    setIsPremiseModal(value) {
      this.isPremiseModalOpen = value;
    },
    closePremiseModal() {
      this.isPremiseModalOpen = false;
      if (this.form.step !== 2) {
        this.$emit('input', {
          assumption: this.assumption,
          assumptionData: this.form.selectedPremise,
        });
      }
      this.form.step = 0;
    },
    closeValidateModal() {
      this.isValidateModalOpen = false;
    },
    onBack() {
      this.form.step = 0;
    },
    onFinish(assumption) {
      this.assumption = assumption;
      this.closePremiseModal();
    },
    async onValidateAssumption(assumption) {
      // take value description and send request to ai to get referace the question

      // share the question t the input.

      // const results = `I have ${this.title} which is ${this.descriptionData}.  How do I rephrase this in a way that asks for feedback from the public in 150 words.`;

      // try {
      //   const res = await getAI({
      //     context: results,
      //   });
      //   this.result = res.data.data;
      // } catch (error) {
      //   console.log(error);
      //   this.$toast({
      //     title: 'Error',
      //     description: `Sorry an error has occured `,
      //     status: 'success',
      //     position: 'top',
      //     duration: 3000,
      //   });
      // }

      this.assumption = assumption;
      this.closePremiseModal();
      this.isValidateModalOpen = true;
    },
    onDone(complete) {
      if (this.form.step === 0) {
        if (
          this.form.selectedStatus === 'No' ||
          this.form.selectedStatus === 'Not yet! I need time'
        ) {
          this.form.step = 3;
        } else if (this.form.selectedStatus === 'Yes') {
          this.form.step = 1;
        }
      } else if (this.form.step === 1 && complete) {
        this.assumption = 'verified';
        this.$emit('input', {
          assumption: this.assumption,
          assumptionData: this.form.selectedPremise,
        });
        this.form.step = 2;
      }
    },
  },
  created() {
    if (this.value) {
      this.assumption = this.value;
    }
  },
  computed: {
    selectedAssumption() {
      return this.assumptionSteps.find(
        (step) => step.value === this.assumption
      );
    },
    isDisabled() {
      let result = true;
      if (this.form.step === 0) {
        if (this.form.selectedStatus) result = false;
      } else if (this.form.step === 1) {
        if (this.form.selectedPremise.length > 0) result = false;
      }

      return result;
    },
  },
  watch: {
    value() {
      this.assumption = this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.assumption {
  &__select {
    min-width: 250px;

    &__option,
    &__item {
      @apply h-full w-full font-medium flex items-center justify-center px-4 text-xs cursor-pointer;
      border-radius: 8px;
      padding: 0 10px;
      height: 35px;
      svg {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
    &__item {
      @apply justify-start text-sm;
    }
  }
}
</style>
