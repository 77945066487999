import { render, staticRenderFns } from "./TaskCard.vue?vue&type=template&id=3fb9f250&scoped=true"
import script from "./TaskCard.vue?vue&type=script&lang=js"
export * from "./TaskCard.vue?vue&type=script&lang=js"
import style0 from "./TaskCard.vue?vue&type=style&index=0&id=3fb9f250&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fb9f250",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CBox: require('@chakra-ui/vue').CBox, CButton: require('@chakra-ui/vue').CButton, CHeading: require('@chakra-ui/vue').CHeading, CLink: require('@chakra-ui/vue').CLink, CSpinner: require('@chakra-ui/vue').CSpinner, CStack: require('@chakra-ui/vue').CStack, CDrawerOverlay: require('@chakra-ui/vue').CDrawerOverlay, CDrawerCloseButton: require('@chakra-ui/vue').CDrawerCloseButton, CFlex: require('@chakra-ui/vue').CFlex, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList, CAvatar: require('@chakra-ui/vue').CAvatar, CPopoverTrigger: require('@chakra-ui/vue').CPopoverTrigger, CPopoverBody: require('@chakra-ui/vue').CPopoverBody, CPopoverContent: require('@chakra-ui/vue').CPopoverContent, CPopover: require('@chakra-ui/vue').CPopover, CDrawerBody: require('@chakra-ui/vue').CDrawerBody, CDrawerFooter: require('@chakra-ui/vue').CDrawerFooter, CDrawerContent: require('@chakra-ui/vue').CDrawerContent, CDrawer: require('@chakra-ui/vue').CDrawer})
